import { ActionContext } from 'vuex/types/index'
import chat_item from './chat_item'
import chat_message from './chat_message'
import chat_user from './chat_user'
import { AxiosRequestConfig } from 'axios'
import state, { State } from '../state'
import ChatItem from '@/store/packages/Chat/ChatItem'
import { w3cwebsocket as WebSocketClient, IMessageEvent } from 'websocket'

export default {
  async init ({ dispatch, state, rootGetters }: ActionContext<State, any>) {
    // El debounce es para retrazar la ejecusion de esta funcion cada vez que el chat cambie
    // @ts-ignore
    this._vm.$watch(() => state.currChat, async (chat: ChatItem | null) => {
      // Si no hasy chay o no hay ultimo mensaje, se retorna
      if (!chat || !chat.lastChatMessage?.id_chat_message || chat.unreadedChatMessages === 0) return

      const id_chat_user = chat.getOwnChatUserId()
      // Si no se encuentra el propio id_chat_user, se retorna
      if (!id_chat_user) return

      try {
        const chatUsersData: { id_chat_user: number; id_user: number; chu_last_seen_message_id: number }[] = await dispatch('updateLastUserMessageSeenAndGetUsersLastConection', {
          id_chat: chat.id_chat,
          id_chat_message: chat.lastChatMessage.id_chat_message,
          id_chat_user,
          id_user: rootGetters['auth/userId']
        })

        // Actualizo el status de los usuarios del chat
        chat.updateChatUsersByIChatUsers(chatUsersData)
        // Actualizo el status de los mensajes tambien
        chat.updateUserMessagesStatus()
        // Actualizo los mensajes no leidos
        chat.updateUnreadedChatMessages()
      } catch (error) {
        console.error(error)
      }
    })

    // Cada 5 minutos se actualiza el estado de los chats en caso de que el socket no funcione correctamente
    // En caso de que el usuario tenga mensajes no leidos, se reproduce un sonido
    const FIVE_MINUTES_IN_MS = 5 * 60 * 1000
    setInterval(async () => {
      await dispatch('rehidratateChats')
      const userHaveUnreadedMessages: boolean = await dispatch('userHaveUnreadedMessages')
      if (userHaveUnreadedMessages) {
        dispatch('app/playSound', { type: 'message' }, { root: true })
      }
    }, FIVE_MINUTES_IN_MS)

    await dispatch('reloadChats')
    await dispatch('initChatSocket', { id_chat: state.currChat?.id_chat || 0 })
  },
  forceOpenChat ({ commit, state }: ActionContext<State, any>, { chat, id_chat_message }: { chat: ChatItem; id_chat_message?: number }) {
    commit('SET_STATE', { drawer: true })
    commit('SET_CURR_CHAT', chat)
    if (id_chat_message) {
      // @ts-ignore
      this._vm.$nextTick(() => {
        setTimeout(() => {
          state.currChatComponent.$vuetify.goTo(`#chat-message-${id_chat_message}`, {
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
            container: '.chat_body_container'
          })
        }, 500)
      })
    }
  },
  hideDraweChats ({ commit }: ActionContext<State, any>) {
    commit('SET_STATE', { drawer: false })
    commit('SET_CURR_CHAT', null)
  },
  initChatSocket ({ dispatch, rootGetters, state }: ActionContext<State, any>) {
    const isHttps = window.location.protocol === 'https:'
    const protocol = isHttps ? 'wss:' : 'ws:'
    const host = process.env.VUE_APP_HOST || window.location.host
    const ids_chats = Object.keys(state.chats).join(',')
    // La variable urlConection deberia lucir algo como esto:
    // wss://localhost:8080/socket/chat/1?ids_chats=1,2,3&id_account=1
    const urlConection = `${protocol}//${host}/socket/chat/${rootGetters['auth/userId']}?ids_chats=${ids_chats}&id_account=${rootGetters['auth/accountId']}`

    const client = new WebSocketClient(urlConection)

    client.onopen = function () {
      console.log('WebSocket Client Connected')
    }

    client.onerror = function (error) {
      dispatch('app/addLogWithError', { title: 'SOCKET_CHAT_ERROR', color: 'error', message: '', error }, { root: true })
      console.log(error)
    }

    client.onclose = async function (event) {
      dispatch('app/addLogWithError', { title: 'SOCKET_CHAT_CLOSE', color: 'info', message: '', error: event }, { root: true })
      console.log(event)
      // Se reintentara la coneccion en 5 segundos si el chat existe
      setTimeout(() => dispatch('initChatSocket'), 5000)
    }

    client.onmessage = function (event: IMessageEvent) {
      const data: { data: any; event: string } = JSON.parse(event.data.toString())

      const objEventsActions: { [key: string]: string } = {
        connected: 'socket_connected',
        chat_created: 'socket_ChatCreated',
        chat_updated: 'socket_ChatUpdated',
        chat_thumbnail_updated: 'socket_ChatThumbnailUpdated',
        chat_deleted: 'socket_ChatDeleted',
        chat_group_binded: 'socket_ChatGroupBinded',
        chat_group_unbinded: 'socket_ChatGroupUnBinded',
        chat_user_updated: 'socket_ChatUserUpdated',
        chat_users_added: 'socket_ChatUserAdded',
        chat_users_removed: 'socket_ChatUsersRemoved',
        chat_users_updated: 'socket_ChatUsersUpdated',
        chat_message_added: 'socket_chatMessageAdded',
        chat_messages_added: 'socket_chatMessagesAdded',
        chat_message_updated: 'socket_chatMessageUpdated',
        chat_message_deleted: 'socket_chatMessageDeleted',
        chat_user_last_seen_changed: 'socket_chatUserLastSeenChanged',
        chat_bot_unknown_question: 'socket_chatBotUnknownQuestions',
        chat_bot_error: 'socket_chatBotError',
        chat_bot_unknown_destinations: 'socket_chatBotUnknownDestinations',
        chat_file_attached_added: 'socket_chatFileAttachedAdded',
        chat_file_attached_error: 'socket_chatFileAttachedError',
        chat_file_attached_deleted: 'socket_chatFileAttachedDeleted',
        chat_file_attached_updated: 'socket_chatFileAttachedUpdated',
        user_conected: 'socket_userConected',
        user_disconected: 'socket_userDisconected'
      }
      dispatch(`${objEventsActions[data.event]}`, data.data)
    }
  },
  socket_connected ({ commit }: ActionContext<State, any>, id_connection: number) {
    commit('SET_STATE', { id_connection })
  },
  async axios ({ dispatch }: ActionContext<State, any>, options: AxiosRequestConfig) {
    const data = await dispatch('app/axios',
      { ...options, headers: { chatSID: state.id_connection, ...options.headers } },
      { root: true }
    )
    return data
  },
  ...chat_item,
  ...chat_message,
  ...chat_user
}
