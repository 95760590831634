import getMethodNameArgs from "@/utils/getMethodNameArgs";
import SkyWorldSDK from "..";
import DevicesApi from "./DevicesApi";
import { AxiosResponse } from "axios";
import { trycatch } from "@/utils/trycatch";

export default class Api {
    public sdk: SkyWorldSDK;
    public items: {
        devices: DevicesApi;
    };
    private intervalId: number | null;

    constructor(sdk: SkyWorldSDK) {
        this.sdk = sdk;
        this.intervalId = null;
        const thisProxy = this.initHandler();
        this.items = {
            devices: new DevicesApi(thisProxy),
        };
        return thisProxy;
    }

    public startObserverMessages() {
        this.initObserverMessages();
    }

    public stopObserverMessages() {
        if (this.intervalId) clearInterval(this.intervalId);
    }

    private initObserverMessages() {
        if (this.sdk.config.disableInterval) return;

        // @ts-ignore
        this.intervalId = setInterval(async () => {
            if (!this.sdk.config.apiToken || !this.sdk.config.apiId) return;
            const apiItems = Object.values(this.items);

            await Promise.all(
                apiItems.map(
                    async (apiItem) =>
                        await trycatch(() => apiItem.observeEvents(), null, { logError: this.sdk.IS_DEV })
                )
            );

        }, this.sdk.config.intervalTime);
    }

    /**
     * Assigns the given data to the items of the given class.
     * The given data is expected to be an array of objects with the same structure as the class of the items.
     * The method returns an array of items of the given class, with the data of each item assigned to the corresponding properties of the item.
     *
     * @template ItemType - The type of the data to be assigned to the items.
     * @template ItemClass - The class of the items to be created.
     * @param data - The data to be assigned to the items.
     * @param ConstrucClassItem - The class of the items to be created.
     * @returns An array of items of the given class, with the data of each item assigned to the corresponding properties of the item.
     */
    public assingToItems<ItemType, ItemClass>(
        data: any[],
        ConstrucClassItem: any
    ) {
        return data.map(
            (item: ItemType) => new ConstrucClassItem({ data: item })
        ) as ItemClass[];
    }

    private initHandler() {
        const self = this;
        return new Proxy<typeof this>(this, {
            /**
             * Intercepta las llamadas a metodos de la clase `Api`.
             *
             * Si el metodo no existe en la clase `Api`, se asume que es un metodo de la API de SkyWorld y se llama al metodo `caller` para manejarlo.
             *
             * @param target - El objeto que se esta llamando (la clase `Api` en este caso).
             * @param prop - El nombre del metodo que se esta llamando.
             * @returns El resultado de la llamada al metodo.
             */
            get(target: any, prop: string) {
                if (prop in target) {
                    return (target as any)[prop];
                }
                return function (...args: any[]) {
                    /**
                     * Llamada al metodo `caller` para manejar la llamada al metodo de la API de SkyWorld.
                     *
                     * @param prop - El nombre del metodo de la API que se esta llamando.
                     * @param args - Los argumentos que se pasan al metodo de la API.
                     * @returns El resultado de la llamada al metodo.
                     */
                    return self.caller(prop, args.length > 0 ? args[0] : {});
                };
            },
        });
    }

    private async caller(
        method: string,
        args: { [key: string]: any } = {}
    ): Promise<any> {
        const [httpMethodUnderScore, methodPathUnderScore] =
            getMethodNameArgs(method);
        const httpMethod = httpMethodUnderScore.replace(/_/g, "");
        const methodPath = methodPathUnderScore.replace(/_/g, "/");
        const url = `${this.sdk.config.baseUrl}/${methodPath}`;
        const defaultBody = {
            id: this.sdk.config.apiId,
            token: this.sdk.config.apiToken ? this.sdk.config.apiToken.trim() : "",
        };

        if (this.sdk.IS_DEV) {
            console.log("Method name: ", method);
            console.log("HttpMethod: ", httpMethod);
            console.log("Path: ", methodPath);
            console.log("Args: ", args);
            console.log("URL: ", url);
        }

        const response: AxiosResponse<any> = await this.sdk.axios({
            url,
            method: httpMethod,
            data: Object.keys(args).length > 0 ? args : defaultBody,
            headers: {
                "content-type": "text/plain;charset=UTF-8",
            }
        });

        return response.data;
    }
}
