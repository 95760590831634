import SkyWorldSDK from '@/submodules/skyworldsdk'
import Device from '@/submodules/skyworldsdk/dist/Items/Device'

const state = () => ({
  PROXY_URL: process.env.VUE_APP_SKYWORLD_PROXY!,
  currentDevices: [] as Device[],
  sdk: null as SkyWorldSDK | null
})

export type State = ReturnType<typeof state>;

export default state
