import Api from './Api';
import { IAuthLogin } from './interfaces/Auth.interface';
import sdkConfigs, { ISDKParams } from './sdkConfigs'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export default class SkyWorldSDK {
    private initialConfig: ISDKParams;
    public api: Api

    constructor(config: ISDKParams) {
        this.initialConfig = sdkConfigs(config);
        this.api = new Api(this);
        if (!this.config.disableInterval) this.api.startObserverMessages();
        if (this.IS_DEV) console.table(this.config)
    }

    public async login() {
        // @ts-ignore
        const auth: IAuthLogin = await this.api['post_auth-key.php']({ 
            usuario: this.config.username,
            password: this.config.password
        }) 
        if (this.IS_DEV) console.log(`Login: ${JSON.stringify(auth)}`)
        this.config.apiId = auth.result.id
        this.config.apiToken = auth.result.token
    }

    public set config(config: ISDKParams) {
        this.initialConfig = config
    }

    public get config(): Required<ISDKParams> {
        // @ts-ignore
        return this.initialConfig;
    }

    public async axios<D = any>(reqConfig: AxiosRequestConfig = {}) {
        const instance = axios.create(reqConfig)
        return await instance(reqConfig) as AxiosResponse<D>
    }

    /** Whether the SDK is in debug mode. */
    public get IS_DEV() {
        return this.config.debug
    }
}

