/* eslint-disable @typescript-eslint/ban-types */

import { ActionContext } from 'vuex/types/index'
import { State } from './state'
import { IFile } from '@/interfaces/IFIle.interface'
import objToFormData from '@/utils/objToFormDataDeep'

export default {
  async init ({ commit, dispatch, rootGetters, rootState }: ActionContext<State, any>) {
    try {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: true,
          message: 'Descargando Recursos'
        },
        { root: true }
      )
      await dispatch('loadAtrrUsers', 'CONCEPTOS')
      await dispatch('loadAtrrUsers', 'TALLERES')
      // await dispatch('travel/unitsMonitoring', {}, { root: true })
    } catch (error) {
      console.error(error)
      commit(
        'app/ADD_LOG',
        {
          title: 'OBTENIENDO RECURSOS',
          color: 'error',
          message: error
        },
        { root: true }
      )
    } finally {
      commit(
        'app/UPDATE_LOADER',
        {
          loader: false,
          message: 'Descargando Recursos'
        },
        { root: true }
      )
    }
  },
  async createMantto ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit_maintenance/create',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async updateMantto ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/unit_maintenance/update/${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async deleteMantto ({ commit, dispatch }: ActionContext<State, any>, id_mantto: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/unit_maintenance/delete/${id_mantto}`,
        method: 'DELETE'
      },
      { root: true }
    )
    return data
  },
  async resetKilometraje ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/reset_km_unit',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async resetEngineHour ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/reset_engine_hour',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadMantto ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit_maintenance/load_maintenance',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadAllMantto ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit_maintenance/get/all',
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_MANTTOS', data)
    return data
  },
  async loadTire ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/load_tire_all',
        method: 'GET',
        data: payload
      },
      { root: true }
    )
  },
  async createTire ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_tire',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('travel/PUSH_ITEM_TIRE', data, { root: true })
    return data
  },
  async createBattery ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_battery',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('travel/PUSH_ITEM_BATTERY', data, { root: true })
    return data
  },
  async updateBattery ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_battery',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('travel/UPDATE_ITEM_BATTERY', data, { root: true })
    return data
  },
  async updateTire ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_tire',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('travel/UPDATE_ITEM_TIRE', data, { root: true })
    return data
  },
  async loadEmailUsers ({ commit, dispatch }: ActionContext<State, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/get_emails_user',
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async createEmailUsers ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_email_user',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async updateEmailUsers ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_email_user',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async deleteEmailUsers ({ commit, dispatch }: ActionContext<State, any>, id_user_email: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/delete_email_user/${id_user_email}`,
        method: 'POST'
      },
      { root: true }
    )
    return data
  },
  async updateUnitConsole ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/update',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async loadEmailUnit ({ commit, dispatch }: ActionContext<State, any>, id_unit_email: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/get_email_unit/${id_unit_email}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async createEmailUnit ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_email_unit',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async updateEmailUnit ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_email_unit',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async deleteEmailUnit ({ commit, dispatch }: ActionContext<State, any>, id_user_email: number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/delete_email_unit/${id_user_email}`,
        method: 'POST'
      },
      { root: true }
    )
    return data
  },
  async loadAtrrUsers ({ commit, dispatch }: ActionContext<State, any>, type_atrr: string) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/get_atrr_user/${type_atrr}`,
        method: 'GET'
      },
      { root: true }
    )
    if (type_atrr === 'TALLERES') {
      commit('SET_TALLERES', data)
    }
    if (type_atrr === 'CONCEPTOS') {
      commit('SET_CONCEPTOS', data)
    }
    if (type_atrr === 'BRANDBATTERY') {
      commit('SET_CAT_TIRE', { data, cat_type: type_atrr })
    }
    if (type_atrr === 'MODELBATTERY') {
      commit('SET_CAT_TIRE', { data, cat_type: type_atrr })
    }
    return data
  },
  async createAtrrUsers ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_atrr_user',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    if (payload.type === 'TALLER') {
      commit('PUSH_TALLERES', data)
    }
    if (payload.type === 'CONCEPTO') {
      commit('PUSH_CONCEPTOS', data)
    }
    if (payload.type === 'BRANDBATTERY') {
      commit('PUSH_ITEM_CAT_TIRE', { item: data, cat_type: payload.type })
    }
    if (payload.type === 'MODELBATTERY') {
      commit('PUSH_ITEM_CAT_TIRE', { item: data, cat_type: payload.type })
    }
    return data
  },
  async updateAtrrUsers ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_atrr_user',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    if (payload.type === 'TALLER') {
      commit('UPDATE_TALLERES', data)
    }
    if (payload.type === 'CONCEPTO') {
      commit('UPDATE_CONCEPTOS', data)
    }
    if (payload.type === 'BRANDBATTERY') {
      commit('UPDATE_ITEM_CAT_TIRE', { item: data, cat_type: payload.type })
    }
    if (payload.type === 'MODELBATTERY') {
      commit('UPDATE_ITEM_CAT_TIRE', { item: data, cat_type: payload.type })
    }
    return data
  },
  async deleteAtrrUsers ({ commit, dispatch }: ActionContext<State, any>, payload: { type: string, id: number }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/delete_atrr_user/${payload.type}/${payload.id}`,
        method: 'POST'
      },
      { root: true }
    )
    if (payload.type === 'TALLER') {
      commit('DELETE_TALLERES', payload.id)
    }
    if (payload.type === 'CONCEPTO') {
      commit('DELETE_CONCEPTOS', payload.id)
    }
    return data
  },
  async loadCatTire ({ commit, dispatch }: ActionContext<State, any>, cat_type: string) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/get_cat_tire/${cat_type}`,
        method: 'GET'
      },
      { root: true }
    )
    commit('SET_CAT_TIRE', { data, cat_type })
    return data
  },
  async createCatTire ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/create_cat_tire',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('PUSH_ITEM_CAT_TIRE', { cat_type: payload.cat_type, item: data })
    return data
  },
  async updateCatTire ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/update_cat_tire',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    commit('UPDATE_ITEM_CAT_TIRE', { cat_type: payload.cat_type, item: data })
    return data
  },
  async deleteCatTire ({ commit, dispatch }: ActionContext<State, any>, payload: { type: string, id: number }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/delete_atrr_user/${payload.type}/${payload.id}`,
        method: 'POST'
      },
      { root: true }
    )
    if (payload.type === 'TALLER') {
      commit('DELETE_TALLERES', payload.id)
    }
    if (payload.type === 'CONCEPTO') {
      commit('DELETE_CONCEPTOS', payload.id)
    }
    return data
  },
  async changeUnitConsoleThumbnail ({ dispatch }: ActionContext<State, any>, payload: { id_unit_console: number, thumbnail: File }): Promise<IFile> {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/thumbnails',
        method: 'POST',
        data: objToFormData(payload, null, '')
      },
      { root: true }
    )

    return data
  }
}
