import { ActionContext } from 'vuex'
import { IAccountSocialMedia } from '../interfaces/SocialAccounts.interface'
import { trycatchAsync } from '@/utils/trycatch'
import objToFormData from '@/utils/objToFormDataDeep'
import { State } from './state'
import { PUBLISH_ACCOUNT_ASSETS } from '../catalogs/PUBLISH_ACCOUNT_ASSETS'

export default {
  async init (context: ActionContext<State, any>) {
    await Promise.all([
      trycatchAsync(async () => await context.dispatch('getAllAvaliableSocialAccounts'), null),
      trycatchAsync(async () => await context.dispatch('facebook/init'), null)
    ])
    // await trycatchAsync(async () => await context.dispatch('getAccountsAssets', {
    //   ids_account_social_mediaids_account_social_media: context.state.socialAccounts.map(account => account.id_account_social_media),
    //   assets_types: [PUBLISH_ACCOUNT_ASSETS.THUMBNAIL]
    // }), null)
  },
  async createSocialAccountItem ({ dispatch, commit }: ActionContext<any, any>, payload: any): Promise<IAccountSocialMedia[]> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      commit('ADD_SOCIAL_ACCOUNTS', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'CREATE_SOCIAL_ACCOUNT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async deleteSocialAccountItem ({ dispatch, commit, state }: ActionContext<any, any>, id_account_social_media: number): Promise<void> {
    try {
      await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/',
          method: 'DELETE',
          data: { id_account_social_media }
        },
        { root: true }
      )
      const index = state.socialAccounts.findIndex((p: IAccountSocialMedia) => p.id_account_social_media === id_account_social_media)
      if (index === -1) return

      commit('DELETE_SOCIAL_ACCOUNT', index)
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'DELETE_SOCIAL_ACCOUNT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getAllAvaliableSocialAccounts ({ commit, dispatch }: ActionContext<any, any>) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/',
          method: 'GET'
        },
        { root: true }
      )

      commit('SET_SOCIAL_ACCOUNTS', data)

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_SOCIAL_ACCOUNTS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async publishSocialAccountPost ({ state, dispatch }: ActionContext<State, any>): Promise<{ [key: string]: { success: boolean, payload: any } }> {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/publish',
          method: 'POST',
          data: objToFormData({
            ...state.publishingPreviewData.form,
            ids_account_social_media: state.publishingPreviewData.selectedAccounts.map(account => account.id_account_social_media)
          }, null, '')
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'PUBLISH_SOCIAL_ACCOUNT', color: 'error', message: '', error }, { root: true })
      throw error
    }
  },
  async getAccountsAssets ({ dispatch }: ActionContext<State, any>, payload: { ids_account_social_mediaids_account_social_media: number[], assets_types: PUBLISH_ACCOUNT_ASSETS[] }) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: '/apis/social_media_accounts/assets',
          method: 'POST',
          data: payload
        },
        { root: true }
      )

      return data
    } catch (error) {
      console.error(error)
      dispatch('app/addLogWithError', { title: 'GET_ACCOUNTS_ASSETS', color: 'error', message: '', error }, { root: true })
      throw error
    }
  }
}
