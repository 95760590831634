export interface DefaultParams {
  /** Turn off the interval of events */
  disableInterval?: boolean
  /** Time of the interval of events, default 10 seconds in ms */
  intervalTime?: number
  /** The debug mode of the sdk */
  debug?: boolean,
}

export interface SkyWorldConfig {
  /** Base url of the api */
  baseUrl: string
  /** Api token */
  apiToken?: string
  /** Api id */
  apiId?: number
  /** Username */
  username?: string
  /** Password */
  password?: string
}

const defaultParams = (config: Partial<DefaultParams> & SkyWorldConfig) => {
  const params: DefaultParams & SkyWorldConfig = {
    disableInterval: false,
    intervalTime: 10000,
    debug: false,
    ...config
  }

  // @ts-ignore
  return params
}

export type ISDKParams = ReturnType<typeof defaultParams>;

export default defaultParams
