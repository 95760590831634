import { IAuth } from './auth.interface'

const state = (): IAuth => ({
  is_auth: false,
  auth_token: '',
  plataform_data: [],
  auth_tokenWith: null,
  login_cms: false,
  permissions: {},
  user_date: {
    id: 0,
    id_user: 0,
    service: [],
    us_name: '',
    phone: '',
    // data_userType: [],
    distribuitor: false,
    id_user_profile: 0,
    loader_image: '',
    logo_image: '',
    email: '',
    id_user_mirror: 0,
    account: {
      id: 0,
      name: ''
    },
    thumbnail_file: null
    // id_user_mirror: 0
  },
  service: [],
  developer: false,
  reload: false,
  userBaseAuth: null
})

export type State = ReturnType<typeof state>

export default state
