export enum SERVICES_TYPE{
  VIAJE = 1,
  MANTENIMIENTO = 2,
  LLANTA = 3,
  BATERIA = 4,
  VIDEO = 5,
  MAPAS = 6,
  COMBUSTIBLE = 7,
  CHECKLIST = 8,
  INVENTARIO = 9,
  FACTURACION = 10,
  TICKETS = 11,
  PANEL = 12,
  CRM = 13,
  CAMARAS_SUB = 14,
  EVENTOS = 15,
  ESTADISTICAS = 16,
  GENERAL = 17,
  ACTIVOS = 18,
  VENTAS = 19,
  MARKETING = 20,
  EIR = 21,
  SOCIAL_MEDIA = 22
}
export enum MENU_ADMIN{
  ACCOUNT = 1,
  USERS = 2,
  GESTION_USERS = 3
}
export const menuItem = [
  { id: SERVICES_TYPE.PANEL, text: 'Panel de Control', icon: 'mdi-monitor-dashboard', route: '/Consola/panel_control' },
  {
    text: 'Recursos',
    icon: 'mdi-briefcase-variant',
    children: [
      { text: 'Geocercas', icon: 'mdi-map', route: '/Consola/geocercas' },
      { text: 'Rutas', icon: 'mdi-map', route: '/Consola/rutas' }
    ]
  },
  { text: 'Mapa', icon: 'mdi-google-maps', route: '/Consola/mapas' },
  {
    id: SERVICES_TYPE.VIDEO,
    text: 'Video',
    icon: 'mdi-camera',
    // route: '/Consola/camaras',
    children: [
      { id: SERVICES_TYPE.CAMARAS_SUB, text: 'Cámaras', icon: 'mdi-cctv', route: '/Consola/camaras' },
      { id: SERVICES_TYPE.EVENTOS, text: 'Eventos', icon: 'mdi-bell-ring', route: '/Consola/eventos' },
      // { text: 'Control', icon: 'mdi-camera-timer', route: '/Consola/control' },
      { id: SERVICES_TYPE.ESTADISTICAS, text: 'Estadisticas', icon: 'mdi-chart-areaspline', route: '/Consola/estadisticas' }
    ]
  },

  { id: SERVICES_TYPE.VIAJE, text: 'Viajes', icon: 'mdi-truck-fast', route: '/Consola/viajes' },
  { id: SERVICES_TYPE.COMBUSTIBLE, text: 'Combustible', icon: 'mdi-fuel', route: '/Consola/combustible' },
  { id: SERVICES_TYPE.MANTENIMIENTO, text: 'Mantenimiento', icon: 'mdi-wrench-clock', route: '/Consola/mantenimiento' },
  { id: SERVICES_TYPE.CHECKLIST, text: 'CheckList', icon: 'mdi-pencil-circle-outline', route: '/Consola/checklist/apartados' },

  {
    id: SERVICES_TYPE.INVENTARIO,
    text: 'Inventario',
    icon: 'mdi-store',
    children: [
      { id: SERVICES_TYPE.GENERAL, text: 'General', icon: 'mdi-package-variant', route: '/Consola/inventario' },
      { id: SERVICES_TYPE.ACTIVOS, text: 'Control de Activos', icon: 'mdi-printer-eye', route: '/Consola/control_activos' },
      // { text: 'General', icon: 'mdi-package-variant', route: '/Consola/inventario/nueva-requisicion' },
      { id: SERVICES_TYPE.LLANTA, text: 'Neumáticos', icon: 'mdi-tire', route: '/Consola/neumaticos' },
      // { id: SERVICES_TYPE.LLANTA, text: 'Activos fijos', icon: 'mdi-tire', route: '/Consola/neumaticos' }
      { id: SERVICES_TYPE.BATERIA, text: 'Baterías', icon: 'mdi-car-battery', route: '/Consola/baterias' }
    ]
  },
  {
    id: SERVICES_TYPE.FACTURACION,
    text: 'Facturación',
    icon: 'mdi-file-sign',
    route: '/Consola/facturacion'
    // children: [
    //   { text: 'Gestión', icon: 'mdi-text-box-multiple', route: '/Consola/facturacion' }
    // ]
  },
  {
    id: SERVICES_TYPE.TICKETS,
    text: 'Tickets',
    icon: 'mdi-ticket-outline',
    route: '/Consola/tickets/dashboard'
  },
  {
    id: SERVICES_TYPE.CRM,
    text: 'CRM',
    icon: 'mdi-clipboard-text ',
    children: [
      { id: SERVICES_TYPE.VENTAS, text: 'Ventas', icon: 'mdi-cart', route: '/Consola/CRM' },
      { id: SERVICES_TYPE.MARKETING, text: 'Marketing', icon: 'mdi-bullhorn ', route: '/Consola/marketing' }
    ]
  },
  {
    id: SERVICES_TYPE.EIR,
    text: 'EIR',
    icon: 'mdi-train-car-container',
    route: '/Consola/EIR'
  },
  {
    text: 'Social Media',
    icon: 'mdi-share',
    route: '/Consola/social_media'
  }
]
export const menuAdmin = [
  { id: MENU_ADMIN.ACCOUNT, text: 'Cuentas', icon: 'mdi-briefcase-account', route: '/Consola/account' },
  { id: MENU_ADMIN.USERS, text: 'Usuarios', icon: 'mdi-account', route: '/Consola/users' },
  { id: MENU_ADMIN.GESTION_USERS, text: 'Gestion de usuarios', icon: 'mdi-folder-table', route: '/Consola/gestion_users' }
]
