/**
 * Splits a given string into a prefix and the remaining part based on the first underscore.
 * 
 * @param str - The input string to be split.
 * @returns An array containing the prefix (including the underscore) and the rest of the string.
 * @throws Error if no underscore is found in the input string.
 */
export default function (str: string) {
    // Encontrar el prefijo (primera parte antes del primer "_")
    const underscoreIndex = str.indexOf('_');

    if (underscoreIndex === -1) {
        throw new Error('No underscore found in string');
    }
    
    // Separar en prefijo y el resto del string
    const prefix = str.slice(0, underscoreIndex + 1); // Incluye el guion bajo
    const restOfString = str.slice(underscoreIndex + 1); // Todo lo demás después del guion bajo
    return [prefix, restOfString];
}