/**
 * Wrapper para FB.login
 * @param options Opciones para FB.login
 * @param options.scope String con los permisos que se solicitan. Por defecto es 'email'.
 * @returns Promesa que se resuelve con la respuesta de la autenticación o se rechaza con un error.
 */
export function fbLoginAsync (options = { scope: 'email' }) {
  return new Promise((resolve, reject) => {
    FB.login(function (response) {
      if (response.status === 'connected') {
        resolve(response)
      } else {
        reject(new Error('No se pudo iniciar sesión con Facebook o el usuario canceló.', { cause: response }))
      }
    }, options)
  })
}

/**
 * Wrapper para FB.api
 * @param endpoint Punto de acceso de la API de Facebook
 * @param method Metodo HTTP a utilizar (get, post o delete). Por defecto es get.
 * @param params Parametros adicionales para enviar en la solicitud.
 * @returns Promesa que se resuelve con la respuesta de la API de Facebook o se rechaza con un error.
 */
export function fbApiAsync (endpoint: string, method = 'get' as 'get' | 'post' | 'delete', params = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    FB.api(endpoint, method, params, function (response: any) {
      if (!response || response.error) {
        reject(response ? response.error : new Error('Error desconocido en FB.api', { cause: response }))
      } else {
        resolve(response)
      }
    })
  })
}
