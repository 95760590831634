import IDevice, { IDeviceParams } from "@/interfaces/IDevice.interface";
import BaseItem from "./BaseItem";
import { DateTime } from 'luxon';

export default class Device extends BaseItem<IDevice> implements IDevice {
    imei!: string;
    dt_server!: string;
    dt_tracker!: string;
    lat!: string;
    lng!: string;
    altitude!: string;
    angle!: string;
    speed!: string;
    name!: string;
    device!: string;
    sim_number!: string;
    plate_number!: string;
    params!: "" | IDeviceParams;

    
    /**
     * Compares the device's `dt_tracker` timestamp with the provided one to check if they are the same.
     *
     * @param dt_tracker - The timestamp to compare against the device's `dt_tracker`.
     * @returns True if both timestamps are equal; otherwise, false.
     */
    public hasSameDTTracker(dt_tracker: string): boolean {
        // Definimos el formato de entrada
        const format = "yyyy-MM-dd HH:mm:ss";

        // Parseamos las cadenas de texto a objetos DateTime usando el formato indicado
        const dt1 = DateTime.fromFormat(this.dt_tracker, format);
        const dt2 = DateTime.fromFormat(dt_tracker, format);

        if (!dt1.isValid || !dt2.isValid) {
            return true
        }

        return dt1.equals(dt2);
    }
}