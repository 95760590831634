/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ReportItemData, { ReportItemProperties } from './ReportItemData'
import { UnitConsole } from '@/store/interfaces/ReportModules'
import { PLATAFORM } from '@/store/resources/RESOURCES'
import Device from '@/submodules/skyworldsdk/dist/Items/Device'
import IDevice from '@/submodules/skyworldsdk/dist/interfaces/IDevice.interface'

export default class RIWebFleet
  extends ReportItemData<Device, IDevice>
  implements UnitConsole {
    public bateryFlag = 'V';
    public keyUnitMessage = ''
    public pType = PLATAFORM.WEBFLEET
    public currSpeed = 0

    constructor (unitConsole: UnitConsole, properties: ReportItemProperties<Device>, options = { initObservers: true }) {
      super(unitConsole, properties, options)
      this.setLastData(this.unit_item)
    }

    setLastData (device: IDevice) {
      const lat = Number(device.lat)
      const lng = Number(device.lng)
      if (lat === 0 && lng === 0) return

      this.last_message = {
        ...device,
        pos: {
          x: lng,
          y: Number(device.lat),
          c: lat
        }
      }
    }

    unsusbscribeToUnitMessages (): void {
      // --
    }

    susbscribeToUnitMessages (): void {
      // --
    }

    getName (): string {
      return this.unit_item.name
    }

    getIcon (): string {
      return '/images/truck.png'
    }

    async getIgnitionState (): Promise<number> {
      if (!this.unit_item.params || !this.unit_item.params.acc) return 3
      return Number(this.unit_item.params.acc)
    }

    speed (): number {
      return Number(this.unit_item.speed)
    }

    async getDistanceByTime (params: { from: number; to: number }): Promise<number> {
      return 0
    }
}
