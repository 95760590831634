import UnitConsoleData from '../packages/ReportItem/ReportItemData'
import { ActionContext } from 'vuex'
import { State } from './state'
import { trycatch } from '@/utils/trycatch'
import SkyWorldSDK from '@/submodules/skyworldsdk'
import { PLATAFORM } from '../resources/RESOURCES'

export default {
  async init (context: ActionContext<State, any>) {
    await trycatch(async () => context.dispatch('initSDK'), null)
  },
  async initSDK (context: ActionContext<State, any>) {
    const webfleetData = context.rootGetters['auth/webfleetData']

    if (!webfleetData) {
      if (context.rootState.app.IS_DEV) console.log('No WebFleetData')
      return
    }
    if (context.rootState.app.IS_DEV) console.log('WebFleetData', webfleetData)

    const sdk = new SkyWorldSDK({
      baseUrl: context.state.PROXY_URL,
      debug: context.rootState.app.IS_DEV,
      username: webfleetData.user,
      password: webfleetData.password
    })

    await sdk.login()

    context.commit('SET_STATE', { sdk })

    await context.dispatch('loadDevices')
    await context.dispatch('subscribeToDevicesEvents')
  },
  async loadDevices (context: ActionContext<State, any>) {
    if (!context.state.sdk) return
    const currentDevices = await context.state.sdk.api.items.devices.getDevices()
    context.commit('SET_STATE', { currentDevices })
  },
  subscribeToDevicesEvents (context: ActionContext<State, any>) {
    if (!context.state.sdk) return

    context.state.sdk.api.items.devices.events.on('DEVICES_UPDATED', (devices) => {
      const unitsConsoles = context.rootState.travel.unitsRegisters as UnitConsoleData<any, any>[]
      let device = null
      for (const unitConsole of unitsConsoles) {
        if (unitConsole.pType !== PLATAFORM.WEBFLEET) continue

        device = devices[unitConsole.unit_resource_id]
        if (!device) continue

        // @ts-ignore
        unitConsole.setLastData(device)
      }
    })
  }
}
