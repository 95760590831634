/* eslint-disable arrow-parens */
import { extend } from 'vee-validate'
import { required, email, min, max, image, confirmed, numeric, size, ext } from 'vee-validate/dist/rules'

extend('required', {
  ...required,

  message: 'El campo es requerido'
})

extend('email', {
  ...email,
  message: 'El campo {_field_} debe ser de un correo electronico valido'
})

extend('min', {
  ...min,
  message: 'Minimo {length} caracteres'
})

extend('max', {
  ...max,
  message: 'Maximo {length} caracteres'
})

extend('image', {
  ...image,
  message: 'Seleccione una archivo de imagen valido'
})

extend('size', {
  ...size,
  // @ts-ignore
  message (_fieldName: string, params: any, _data?: any) {
    return `La imagen no debe ser mayor a ${params.size} kb`
  }
})

extend('confirmed', {
  ...confirmed,
  message: 'Los campos deben ser iguales'
})

extend('numeric', {
  ...numeric,
  message: 'El campo {_field_} debe ser de tipo numerico'
})

extend('decimal', {
  validate (value) {
    if (value === null || value === undefined || value === '') {
      return {
        valid: true
      }
    }
    // Expresión regular para validar números decimales
    const decimalRegex = /^-?\d*\.?\d+$/

    if (!decimalRegex.test(value)) {
      return {
        valid: false,
        data: {
          field: '_field_'
        }
      }
    }
    return {
      valid: true
    }
  },
  message: 'El campo debe ser un número valido'
})
extend('minValue', {
  // @ts-ignore
  validate (value, { minValue }) {
    if (value === null || value === undefined || value === '') {
      return true
    }

    return Number(value) >= minValue
  },
  params: ['minValue'],
  message: 'El campo {_field_} debe ser mayor o igual a {minValue}'
})
extend('maxValue', {
  // @ts-ignore
  validate (value, { minValue }) {
    if (value === null || value === undefined || value === '') {
      return true
    }

    return Number(value) <= minValue
  },
  params: ['minValue'],
  message: 'El campo {_field_} debe ser menor o igual a {minValue}'
})
extend('ext', {
  ...ext,
  message (_fieldName: string, params: any, _data?: any) {
    const extencionsArr = Object.entries(params).filter(item => !item[0].startsWith('_'))
    const extencions = `${extencionsArr.map(ext => `${ext[1]}`).join(', ')}`
    return `Solo los archivos ${extencions} son admitidos`
  }
})

extend('url', {
  validate: value => {
    const expresion = /^(ftp|http|https):\/\/[^ "]+$/gm
    const regex = new RegExp(expresion)
    return regex.test(value)
  },
  message: 'El campo {_field_} debe ser una URL valida'
})

extend('elements', {
  validate: value => {
    return value.length >= 2
  },
  message: 'El campo {_field_} debe tener almenos 2 elementos'
})

extend('pwdregex', {
  validate: value => {
    const expresion = /^[a-zA-Z0-9_]*$/
    const regex = new RegExp(expresion)
    return regex.test(value)
  },
  message: 'El campo {_field_} no debe contener espacios en blanco'
})

extend('range-fields', {
  /**
   * Valida si un campo es un rango de numeros
   * Por ejemplo: 1-3,4,5
   * @param value
   */
  validate: value => {
    const expresion = /^(\d+(-\d+)?)(,\d+(-\d+)?)*$/
    const regex = new RegExp(expresion)
    return regex.test(value)
  },
  message: 'El campo {_field_} debe seguir una patro parecido al siguiente: 1-3,4,5'
})

extend('file_mime', {
  // Mensaje de error personalizado
  message: (field, args) => {
    // args es un array de tipos MIME permitidos
    return `El archivo seleccionado debe ser uno de los siguientes tipos: ${args.join(', ')}`
  },

  // Función de validación
  validate: (value, args) => {
    if (!value || !value.length) {
      // Si no hay archivo seleccionado, no validar aquí (puede manejarse con 'required')
      return true
    }

    const file = value[0]
    const mimeType = file.type

    // Verificar si el tipo MIME del archivo está en la lista de permitidos
    return args.includes(mimeType)
  }
})
