import Device from "@/Items/Device";
import Api from ".";
import IDevice, { IDevicesEvents } from "@/interfaces/IDevice.interface";
import EventEmitter from "events";
import TypedEmitter from "typed-emitter";
import { EMERGENCY_EVENTS } from "@/catalogs/EMERGENCY_EVENTS";
import { ALERT_EVENTS } from "@/catalogs/ALERT_EVENTS";

export default class DevicesApi {
    private api: Api
    private lastItems: Map<string, Device> = new Map()
    private iterationCallCount = 0
    public events: TypedEmitter<IDevicesEvents>;
    
    constructor(api: Api) {
        this.api = api        
        this.events = new EventEmitter() as TypedEmitter<IDevicesEvents>;
    }

    private handleDeviceRegistration(currItem: Device | undefined, newDeviceData: Device): boolean {
        if (!currItem) {
            this.lastItems.set(newDeviceData.imei, newDeviceData);
            this.events.emit('DEVICE_ADDED', newDeviceData);
            return false;
        }

        if (!currItem.hasSameDTTracker(newDeviceData.dt_tracker)) {
            return true
        }

        return false
    }

    private emitApiDeviceEvents(currItem: Device, newDeviceData: Device): void {
        if (!currItem.params || !newDeviceData.params) return;

        if (newDeviceData.params.emg !== EMERGENCY_EVENTS.EMPTY && currItem.params.emg !== newDeviceData.params.emg) {
            this.events.emit('DEVICE_EMERGENCY_EVENT', newDeviceData);
        }

        if (newDeviceData.params.evt !== ALERT_EVENTS.EMPTY && currItem.params.evt !== newDeviceData.params.evt) {
            this.events.emit('DEVICE_ALERT_EVENT', newDeviceData);
        }
    }

    private saveItems(items: Device[]) {
        for (const item of items) {
            this.lastItems.set(item.imei, item);
        }
    }

    public async observeEvents() {
        const devices = await this.getDevices();
        if (this.iterationCallCount === 0) {
            this.saveItems(devices);
            this.iterationCallCount++;
            return;
        }

        let currItem = null
        let isUpdated = false
        let devicesUpdated: { [key: string]: Device } = {}
        if (this.api.sdk.IS_DEV) console.log(`Current devices: ${devices.length}`)
        for (const device of devices) {
            currItem = this.lastItems.get(device.imei)
            isUpdated = this.handleDeviceRegistration(currItem, device)
            if (!currItem || !isUpdated) continue;

            this.emitApiDeviceEvents(currItem, device)

            this.lastItems.set(device.imei, device)
            devicesUpdated[currItem.imei] = currItem
        }
        if (this.api.sdk.IS_DEV) console.log(`Updated devices: ${Object.keys(devicesUpdated).length}`)
        if (Object.keys(devicesUpdated).length > 0) {
            this.events.emit('DEVICES_UPDATED', devicesUpdated);
        }
        this.iterationCallCount++;
    }

    public getLastDevicesData() {
        return Object.fromEntries(this.lastItems);
    }

    public async getDevices(): Promise<Device[]> {
        // @ts-ignore
        const devices = await this.api['post_devices.php']()
        const items = this.api.assingToItems<IDevice, Device>(devices, Device)
        return items
    }
}