import ExcelJS from 'exceljs'
import { IExcelJSTableBounds } from './getExcelJSTableBounds'

export const REGEX_TEMPLATE = /@?\{\{(\w+)(?::([^}]+))?\}\}/

export function hasSomeVariableInTemplate (template: string): boolean {
  // ([^\w\s])?  -> opcionalmente un símbolo
  // \{\{        -> dos llaves de apertura
  // [^}]+       -> uno o más caracteres que no sean '}'
  // \}\}        -> dos llaves de cierre
  const regex = /([^\w\s])?\{\{[^}]+\}\}/
  return regex.test(template)
}

export interface IGetTemplateVariables {
  content: string
  args: string[]
  prefix: string
  match: string
}

export interface ITemplateTableShape {
  title: string;
  columnsHeaders: {
    name: string;
    value: string;
  }[];
  rowsHeaders: {
    name: string;
    value: string;
  }[];
  data: number[][];
  props?: ExcelJS.TableProperties
  beforeTableEnd: (tableRef: string, header: ExcelJS.Row, rows: (string | number)[][], bounds: IExcelJSTableBounds, columnInitPosition: number, columnEndPosition: number) => Promise<void>,
  beforeCreateTable: (params: { template: IGetTemplateVariables; tableData: ITemplateTableShape; cell: ExcelJS.Cell; columns: { name: string; }[]; rows: any[][] }) => Promise<void>
}

export function getTemplateVariables (str: string): IGetTemplateVariables[] {
  // ([^\w\s])?  -> opcionalmente un símbolo (no alfanum, no espacio)
  // \{\{        -> dos llaves de apertura
  // ([^}]+)     -> uno o más caracteres que NO sean '}'
  // \}\}        -> dos llaves de cierre
  const regex = /([^\w\s])?\{\{([^}]+)\}\}/g
  const variables: IGetTemplateVariables[] = []
  let match

  while ((match = regex.exec(str)) !== null) {
    // match[1] => símbolo capturado (p.ej. '@', '#', '$', '%', etc.) o undefined si no existe
    // match[2] => todo el contenido dentro de {{ ... }} sin el símbolo
    const prefix = match[1]
    const contentInside = match[2].trim()

    // Buscamos la primera aparición de '::'
    const doubleColonIndex = contentInside.indexOf('::')

    if (doubleColonIndex === -1) {
      // No hay '::' => no hay argumentos
      // @ts-ignore
      variables.push({
        content: contentInside,
        args: [],
        prefix,
        match: str
      })
    } else {
      // Sí hay '::' => separamos el nombre de los argumentos
      const namePart = contentInside.substring(0, doubleColonIndex).trim()
      const argsPart = contentInside.substring(doubleColonIndex + 2).trim()

      // Separamos los argumentos por '|'
      const args = argsPart.length > 0
        ? argsPart.split('|').map(arg => arg.trim())
        : []

      // @ts-ignore
      variables.push({
        content: namePart,
        args,
        prefix,
        match: str
      })
    }
  }

  return variables
}

/**
 * Fills the Excel sheet template by processing each cell and executing a callback
 * function when a variable within a template is matched.
 *
 * @param {ExcelJS.Worksheet} sheet - The ExcelJS worksheet to process.
 * @param {Function} onCellMatched - An asynchronous callback invoked when a cell
 *   contains a template variable. It receives the following parameters:
 *   - {ExcelJS.Cell} cell: The current cell being processed.
 *   - {IGetTemplateVariables} variable: The variable found within the cell.
 *   - {RegExp} usedRegex: The regex used to identify the variable.
 *   - {string} currValue: The current value of the cell.
 *   - {Function} setCellValue: A function to set the cell's value.
 *
 * @returns {Promise<void>} A promise that resolves once all the cell processing
 *   callbacks have been completed.
 */

export async function fillSheetTemplate (
  sheet: ExcelJS.Worksheet,
  onCellMatched: (cell: ExcelJS.Cell, variable: IGetTemplateVariables, usedRegex: RegExp, currValue: string, setCellValue: (value: string) => void) => Promise<void>) {
  const callbackPromises: Promise<void>[] = []

  sheet.eachRow((row) => {
    row.eachCell((cell) => {
      const isMasterCell = cell.master && cell.master !== cell
      if (isMasterCell) return

      // @ts-ignore
      const currValue = typeof cell.text === 'object' && cell.text?.richText ? cell.text.richText.map(item => item.text).join('') : cell.text

      if (!currValue || isMasterCell) return
      if (!hasSomeVariableInTemplate(currValue)) return

      const variables = getTemplateVariables(currValue)
      const setCellValue = (value: string) => { cell.value = value }

      for (const variable of variables) {
        const usedRegex = new RegExp(`([^\\w\\s])?\\{\\{${variable.content}(:([^}]+))?\\}\\}`, 'g')

        const promise = onCellMatched(cell, variable, usedRegex, currValue, setCellValue)
        callbackPromises.push(promise)
      }
    })
  })

  // 2. Esperamos a que se completen todas las descargas de las imágenes
  if (callbackPromises.length > 0) await Promise.all(callbackPromises)
}
