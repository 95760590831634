export interface ICoordenadas {
    lat: number
    lng: number
}

export interface ICGeofence {
    id: number
    geo_folio?: string
    geo_name: string
    geo_description: string
    geo_color: string
    geo_payload: {
        type: number,
        radius?: number
        coordinates: ICoordenadas[] | ICoordenadas
    },
    customer?: any
    b: { cen_x: number, cen_y: number }
    n: string,
    w: number,
    direction?: object
}
export interface GeofencesState {
    geofences: Array<ICGeofence>,
    geofencesCrud: {
        modal: boolean,
        id_group: number
    }
}

const state = (): GeofencesState => ({
  geofences: [],
  geofencesCrud: {
    modal: false,
    id_group: 0
  }
})
export type State = ReturnType<typeof state>;

export default state
