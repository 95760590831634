import { ActionContext } from 'vuex'
import { GeofencesState, ICGeofence, State } from './state'
import { serializeError } from 'serialize-error'
import Geofence from '@/classes/Geofence/Geofence'

export default {

  init (context: ActionContext<GeofencesState, string>) {
    // @ts-ignore
    const console_groups: ConsoleGroup[] = context.rootState.console.consoleGroups

    // @ts-ignore
    const socket: Socket = context.rootState.sys.socket
    socket.on('geofence_console_created', (data: any) => context.dispatch('socket_geofenceConsoleCreated', data))
    socket.on('geofence_console_updated', (data: any) => context.dispatch('socket_geofenceConsoleUpdated', data))
    socket.on('geofence_console_deleted', (data: any) => context.dispatch('socket_geofenceConsoleDeleted', data))
    socket.on('geofence_console_created_many', (data: any) => context.dispatch('socket_geofenceConsoleCreatedMany', data))

    for (let i = 0; i < console_groups.length; i++) {
      const console_group = console_groups[i]
      socket.on(`geofence_route_destination_created_${console_group.id_group}`, (data: any) => context.dispatch('socket_geofencesRouteDestinationCreated', data))
      socket.on(`geofence_route_destination_edit_${console_group.id_group}`, (data: any) => context.dispatch('socket_geofenceRouteDestinationEdit', data))
      socket.on(`geofence_route_destination_deleted_${console_group.id_group}`, (data: any) => context.dispatch('socket_geofenceRuteDestinationDeleted', data))
    }
  },
  async loadGeofences ({ commit, dispatch }: ActionContext<GeofencesState, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/geofences/get_all',
        method: 'GET'
      },
      { root: true }
    )
    // @ts-ignore
    const geofences_format: Array<ICGeofence> = data.data.map(geo => {
      const geofenceData: ICGeofence = {
        id: geo.id,
        geo_color: geo.geo_color,
        geo_folio: geo.geo_folio,
        customer: geo.customer,
        geo_name: geo.geo_name,
        geo_description: geo.geo_description,
        geo_payload: geo.geo_payload,
        n: geo.geo_name,
        b: (geo.geo_payload.type === 3) ? { cen_x: geo.geo_payload.coordinates.lng, cen_y: geo.geo_payload.coordinates.lat } : { cen_x: geo.geo_payload.coordinates[0].lng, cen_y: geo.geo_payload.coordinates[0].lat },
        // b: { cen_x: geo.geo_payload.coordinates[0].lng, cen_y: geo.geo_payload.coordinates[0].lat },
        w: 30
      }
      return new Geofence(geofenceData)
    })
    commit('SET_GEOFENCES', geofences_format)
    return geofences_format
  },
  async getById ({ commit, dispatch }: ActionContext<GeofencesState, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/geofences/${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // @ts-ignore
    const geofenceData: ICGeofence = {
      id: data.id,
      geo_folio: data.geo_folio,
      customer: data.customer,
      geo_color: data.geo_color,
      geo_name: data.geo_name,
      geo_description: data.geo_description,
      geo_payload: data.geo_payload,
      n: data.geo_name,
      b: (data.geo_payload.type === 3) ? { cen_x: data.geo_payload.coordinates.lng, cen_y: data.geo_payload.coordinates.lat } : { cen_x: data.geo_payload.coordinates[0].lng, cen_y: data.geo_payload.coordinates[0].lat },
      w: 30,
      direction: data.direction
    }
    return new Geofence(geofenceData)
    // commit('SET_GEOFENCES', geofences_format)
    return data
  },
  async createConsoleGeofence ({ dispatch, commit, state }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      const geofence = await dispatch(
        'app/axios',
        {
          url: 'apis/geofences/create',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      const geo = { ...geofence.data.data }
      const geo_format: ICGeofence = {
        id: geo.id,
        geo_folio: geo.geo_folio,
        customer: geo.customer,
        geo_color: geo.geo_color,
        geo_name: geo.geo_name,
        geo_description: geo.geo_description,
        geo_payload: geo.geo_payload,
        n: geo.geo_name,
        b: (geo.geo_payload.type === 3) ? { cen_x: geo.geo_payload.coordinates.lng, cen_y: geo.geo_payload.coordinates.lat } : { cen_x: geo.geo_payload.coordinates[0].lng, cen_y: geo.geo_payload.coordinates[0].lat },
        w: 30
      }
      commit('PUSH_GEOFENCE', new Geofence(geo_format))
      return geofence.data.data
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async createConsoleGeofenceMany ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: any): Promise<void> {
    try {
      console.log(payload)
      const { data } = await dispatch(
        'app/axios',
        {
          url: 'apis/geofences/create/many',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      // @ts-ignore
      const geofences: Array<ICGeofence> = data.data.map(geo => {
        const geofenceData: ICGeofence = {
          id: geo.id,
          geo_color: geo.geo_color,
          geo_folio: geo.geo_folio,
          customer: geo.customer,
          geo_name: geo.geo_name,
          geo_description: geo.geo_description,
          geo_payload: geo.geo_payload,
          n: geo.geo_name,
          b: (geo.geo_payload.type === 3) ? { cen_x: geo.geo_payload.coordinates.lng, cen_y: geo.geo_payload.coordinates.lat } : { cen_x: geo.geo_payload.coordinates[0].lng, cen_y: geo.geo_payload.coordinates[0].lat },
          // b: { cen_x: geo.geo_payload.coordinates[0].lng, cen_y: geo.geo_payload.coordinates[0].lat },
          w: 30
        }
        return new Geofence(geofenceData)
      })
      commit('PUSH_GEOFENCES', geofences)
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE_MANY', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async updateConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, payload: ICGeofence): Promise<ICGeofence> {
    try {
      await dispatch(
        'app/axios',
        {
          url: `apis/geofences/update/${payload.id}`,
          method: 'PUT',
          data: payload
        },
        { root: true }
      )
      commit('UPDATE_GEOFENCES', payload)
      return payload
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_UPDATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async deleteConsoleGeofence ({ dispatch, commit }: ActionContext<GeofencesState, string>, id_geofence: number): Promise<void> {
    try {
      await dispatch(
        'app/axios',
        {
          url: `apis/geofences/delete/${id_geofence}`,
          method: 'DELETE'
        },
        { root: true }
      )
      commit('DELETE_GEOFENCE', id_geofence)
    } catch (error) {
      console.error(error)
      commit('sys/ADD_LOG', { title: 'GEOFENCE_DELETE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      throw error
    }
  },
  async createBatch ({ commit, dispatch }: ActionContext<State, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/geofences/batch',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // @ts-ignore
    const geofences: Array<ICGeofence> = data.data.map(geo => {
      const geofenceData: ICGeofence = {
        id: geo.id,
        geo_folio: geo.geo_folio,
        customer: geo.customer,
        geo_color: geo.geo_color,
        geo_name: geo.geo_name,
        geo_description: geo.geo_description,
        geo_payload: geo.geo_payload,
        n: geo.geo_name,
        b: (geo.geo_payload.type === 3) ? { cen_x: geo.geo_payload.coordinates.lng, cen_y: geo.geo_payload.coordinates.lat } : { cen_x: geo.geo_payload.coordinates[0].lng, cen_y: geo.geo_payload.coordinates[0].lat },
        // b: { cen_x: geo.geo_payload.coordinates[0].lng, cen_y: geo.geo_payload.coordinates[0].lat },
        w: 30
      }
      return new Geofence(geofenceData)
    })
    commit('PUSH_GEOFENCES', geofences)
  }
}
