import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import { productos } from './modules/product'
import { customer } from './modules/customer'
import { quotation } from './modules/quotations'
import { note_sale } from './modules/note_sale'
import { dashboard } from './modules/dashboard'
// import { productos } from './productos'
// import { clientes } from './clientes'
// import { cotizaciones } from './cotizaciones'
import { factura } from './modules/factura/index'
import { pago } from './modules/pago/index'
import { sat } from './modules/sat/index'
import { carta_porte } from './modules/carta_porte/index'
import { clausula } from './modules/clausula/index'
import { routes_geofences } from './modules/routes_geofences/index'

export const facturacion: Module<any, RootState> = {
  namespaced: true,
  modules: {
    productos,
    customer,
    quotation,
    note_sale,
    factura,
    pago,
    sat,
    carta_porte,
    dashboard,
    clausula,
    routes_geofences
    // clientes,
    // cotizaciones
  }
}
