
// useDefineForClassFields: false
export default abstract class BaseItem<Itype> {
    protected lastData: Itype | object;

    constructor(params: { data: Itype }) {
        this.lastData = {};
        this.rehidratateData(params.data);
        this.onInitInstance();
    }

    /**
     * A method that is called after the constructor is called.
     * It's used to initialize the class instance.
     *
     * @returns {void}
     */
    public onInitInstance(): void {}

    /**
     * Updates the value of a property in the class instance.
     *
     * @param propName The name of the property to update.
     * @param value The new value for the property.
     */
    public updateProperty(propName: keyof this, value: any) {
        if (
            // @ts-ignore
            typeof this[propName] === 'string' ||
            // @ts-ignore
            typeof this[propName] === 'number' ||
            // @ts-ignore
            typeof this[propName] === 'boolean' ||
            // @ts-ignore
            this[propName] === null ||
            // @ts-ignore
            this[propName] === undefined
        ) {
            // @ts-ignore
            this[propName] = value;
            // @ts-ignore
            this.lastData[propName] = value;
        } else {
            // @ts-ignore
            Object.assign(this[propName], value);
            // @ts-ignore
            Object.assign(this.lastData[propName], value);
        }
    }

    /**
     * Inicialice data for a class instance inherit
     * This method is always executed on the constructor
     *
     * @param data Initial data for this class instance
     */
    public rehidratateData(data: Itype) {
        Object.assign(this, data);
        this.lastData = data;
    }

    /**
     * Rehydrates the data of the class instance with the provided partial data.
     *
     * @param data The partial data to rehydrate the class instance with.
     */
    public rehidratateDataPartial(data: Partial<Itype>) {
        Object.assign(this, data);
        if (this.lastData) Object.assign(this.lastData, data);
    }

    /**
     * Set the last data with the given data.
     *
     * @param {Itype} data - the data to set as the last data
     * @return {void}
     */
    public setLastData(data: Itype): void {
        this.lastData = data;
    }

    // @ts-ignore
    public static createInstance(data: Partial<Itype>) {
        // @ts-ignore
        return new this({ data });
    }

    /**
     * Returns a new object containing only the properties of this object that match the given keys or exclude keys.
     *
     * @param {Object} options - An optional object containing keys and excludeKeys properties.
     * @param {Array} options.keys - An array of keys to include in the new object.
     * @param {Array} options.excludeKeys - An array of keys to exclude from the new object.
     */
    public getObject({ keys = [], excludeKeys = [] }: { keys?: Array<keyof Itype>; excludeKeys?: Array<keyof Itype>; } = {}): Partial<Itype> {
        // @ts-ignore
        return Object.fromEntries(
            Object.entries(this)
                // @ts-ignore
                .filter(([key]) => !keys.length || keys.includes(key))
                // @ts-ignore
                .filter(([key]) => !excludeKeys.includes(key))
        );
    }

    public copy(): this {
        // Usamos Object.assign para copiar las propiedades
        const copy = Object.create(this.constructor.prototype);
        Object.assign(copy, this);
        return copy;
    }
}
