
export interface RouteGeofenceCPState {
  routes_geofences: any[]
}

const state = (): RouteGeofenceCPState => ({
  routes_geofences: []
})
export type State = ReturnType<typeof state>;

export default state
