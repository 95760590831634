export enum SOCIAL_MEDIA_TYPE {
  FACEBOOK = 1,
  INSTAGRAM = 2
}

export const SOCIAL_MEDIA_TYPE_ARRAY = [
  {
    label: 'Facebook',
    id: SOCIAL_MEDIA_TYPE.FACEBOOK
  },
  {
    label: 'Instagram',
    id: SOCIAL_MEDIA_TYPE.INSTAGRAM
  }
]

export const SOCIAL_MEDIA_TYPE_THUMBNAILS = {
  [SOCIAL_MEDIA_TYPE.FACEBOOK]: require('@/assets/svg/facebook.svg'),
  [SOCIAL_MEDIA_TYPE.INSTAGRAM]: require('@/assets/svg/instagram.svg')
}
