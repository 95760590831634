/* eslint-disable import/named */
import { isObject, isArray } from 'lodash'

export default function (obj: any) {
  const formData = new FormData()

  for (const key in obj) {
    if (obj[key] instanceof File) {
      // Si el valor es un archivo, agrégalo al FormData
      formData.append(key, obj[key])
    } else if (typeof obj[key] === 'string' && obj[key]?.startsWith('/media/')) {
      // Si es una ruta existente, omítelo
      console.log(`Omitiendo la ruta existente: ${key} -> ${obj[key]}`)
      continue
    } else if (isArray(obj[key]) || isObject(obj[key]) || obj[key] === '{}') {
      // Si es un objeto o array, conviértelo a JSON
      formData.append(key, JSON.stringify(obj[key]))
    } else if (obj[key] !== undefined) {
      // Si es un valor normal (string o número), agrégalo como está
      formData.append(key, obj[key])
    }
  }

  return formData
}
