
import { ActionContext } from 'vuex'
// import { State } from './state'
import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/user/account/'
const URL_ACTION_ROLE = '/apis/user/account/role'
const actions = {
  async loadGroupByAccount ({ commit, dispatch }: ActionContext<unknown, any>, id_account:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id_account}/groups/`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async updateGroup ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getMembers ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/members/`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async addMembers ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/members/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async removeMembers ({ commit, dispatch }: ActionContext<unknown, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/members/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json' // Asegurar JSON si es necesario
        },
        data: { user_ids: payload.user_ids } // Enviar array en el cuerpo
      },
      { root: true }
    )
    return data
  },
  async createGroup ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async deleteGroup ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/groups/${payload.id}/`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getByIdRole ({ commit, dispatch }: ActionContext<unknown, any>, payload: any) {
    console.log(payload)
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/${payload.id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async createRole ({ commit, dispatch }: ActionContext<unknown, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async loadRoles ({ commit, dispatch }: ActionContext<unknown, any>, id_account:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id_account}/roles/`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async updateRole ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/${payload.id}/`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async deleteRole ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account}/roles/${payload.id}/`,
        method: 'DELETE'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async savePermissionsRole ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.account_id}/roles/${payload.role_id}/permissions/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  }
  // async getRl ({ commit, dispatch }: ActionContext<unknown, any>, payload:any) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `${URL_ACTION}${payload.account_id}/roles/${payload.id}/permissions/`,
  //       method: 'POST',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   // commit('SET_ALL', data)
  //   return data
  // }
}
export default actions
