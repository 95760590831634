
import { Module } from 'vuex'
// @ts-ignore
import { RootState } from '@/store/types'
import actions from './actions'
// import getters from './getters'
import state, { RouteGeofenceCPState } from './state'

export const routes_geofences: Module<RouteGeofenceCPState, RootState> = {
  namespaced: true,
  state,
  // getters,
  actions
}
