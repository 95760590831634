
import { ActionContext } from 'vuex'
// import { State } from './state'
import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/clausula/'
const actions = {
  async loadAll ({ commit, dispatch }: ActionContext<unknown, any>) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async getById ({ commit, dispatch }: ActionContext<unknown, any>, id:number) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${id}`,
        method: 'GET'
      },
      { root: true }
    )
    // commit('SET_ALL', data)
    return data
  },
  async create ({ commit, dispatch }: ActionContext<unknown, any>, payload: ICustomer) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: URL_ACTION,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async createBatch ({ commit, dispatch }: ActionContext<unknown, any>, payload: ICustomer) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}batch/`,
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    // commit('PUSH', data)
    return data
  },
  async update ({ commit, dispatch }: ActionContext<unknown, any>, payload: ICustomer) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `${URL_ACTION}${payload.id}`,
        method: 'PUT',
        data: payload
      },
      { root: true }
    )
    // commit('UPDATE', data)
    return data
  }
}
export default actions
