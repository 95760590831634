
import { ActionContext } from 'vuex'
import { State } from './state'
import { serializeError } from 'serialize-error'
import { isArray } from 'lodash'
import axios from 'axios'
// import { ICustomer } from '@/classes/Facturacion/ICustomer'
const URL_ACTION = '/apis/unit/'
const actions = {
  async getById ({ commit, dispatch, rootState }: ActionContext<State, any>, payload : { id: number }) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: `/apis/unit/${payload.id}`,
        method: 'GET'
      },
      { root: true }
    )
    return data
  },
  async loadUnitsByAccount ({ commit, dispatch, rootState }: ActionContext<State, any>, payload : { id: number }) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    rootState.administration.userEdit.cancel = source.cancel
    rootState.administration.userEdit.loaded = true
    // rootState.administration.userEdit.units = []
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/unit/get/all/account-by?update=1&account=${payload.id}`,
          method: 'GET',
          cancelToken: source.token
        },
        { root: true }
      )
      // rootState.administration.userEdit.units = data
      rootState.administration.userEdit.cancel = null
      rootState.administration.userEdit.loaded = false
      return data
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('Error en la petición:', error)
      }
    }
    return []
  },
  async loadUnitsSelect ({ commit, dispatch, rootState }: ActionContext<State, any>, payload: any) {
    const { data } = await dispatch(
      'app/axios',
      {
        url: '/apis/unit/get-units',
        method: 'POST',
        data: payload
      },
      { root: true }
    )
    return data
  },
  async deleteUnitsConsole ({ commit, dispatch }: ActionContext<State, any>, id_unit_console: number) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `/apis/unit/delete/${id_unit_console}`,
          method: 'GET'
        },
        { root: true }
      )
    } catch (error) {
      commit('sys/ADD_LOG', { title: 'GEOFENCE_CREATE', color: 'error', message: error, payload: serializeError(error) }, { root: true })
      await dispatch('app/notification', { content: 'Ha ocurrido un error', type: 'error' }, { root: true })
    }
  },
  async getKmByDate ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: `${URL_ACTION}get_km_by_date`,
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      await dispatch('app/notification', { content: 'Ha ocurrido un error', type: 'error' }, { root: true })
      return ''
    }
  },
  async updatereminder ({ dispatch }: ActionContext<State, any>, payload: any) {
    try {
      const { data } = await dispatch(
        'app/axios',
        {
          url: 'apis/mantto/reminder',
          method: 'POST',
          data: payload
        },
        { root: true }
      )
      return data
    } catch (error) {
      await dispatch('app/notification', { content: 'Ha ocurrido un error', type: 'error' }, { root: true })
      return ''
    }
  }
  // async create ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: URL_ACTION,
  //       method: 'POST',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('PUSH', data)
  //   return data
  // },
  // async update ({ commit, dispatch }: ActionContext<State, any>, payload: any) {
  //   const { data } = await dispatch(
  //     'app/axios',
  //     {
  //       url: `${URL_ACTION}${payload.id}`,
  //       method: 'PUT',
  //       data: payload
  //     },
  //     { root: true }
  //   )
  //   commit('UPDATE', data)
  //   return data
  // }
}
export default actions
