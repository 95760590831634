export enum ALERT_EVENTS { 
    EMPTY = "0",
    GPS_ANTENNA_CONNECTED = "3",
    GPS_ANTENNA_DISCONNECTED = "4",
    IGNITION_ON = "33",
    IGNITION_OFF = "34",
    POWER_SUPLY_RECONNECTION = "40",
    POWER_SUPLY_DISCONNECTION = "41",
    TWO_WAY_CALL = "79",
}

export const ALERT_EVENTS_SET = new Set(Object.values(ALERT_EVENTS))
