// @ts-nocheck
import { State } from './state'
import { USER_TYPES } from './USER_TYPES'
import { SERVICES, SERVICES_ARR } from '../catalogs/SERVICES'
import { PLATAFORM } from '../resources/RESOURCES'
import { account } from '../administration/modules/account'
import { MENU_ADMIN, menuAdmin } from '@/utils/menu/menu'

export default {
  userId (state: State) {
    return state.user_date.id_user
  },
  username (state: State) {
    return state.user_date.us_name
  },
  role (state: State) {
    if (state.user_date.data_userType[0].id === USER_TYPES.MONITOREO) return 'Empresa de rastreo'
    if (state.user_date.data_userType[0].id === USER_TYPES.ADMINISTRADOR) return 'Administrador'
    if (state.user_date.data_userType[0].id === USER_TYPES.CLIENTE) return 'Cliente'
    if (state.user_date.data_userType[0].id === USER_TYPES.SUPERVISOR) return 'Supervisor'
    if (state.user_date.data_userType[0].id === USER_TYPES.USER_MIRROR) return 'Espejo'
    return 'Sin datos'
  },
  roleId (state: State) {
    if (!state.user_date.data_userType) return null
    if (!state.user_date.data_userType[0]) return null

    return state.user_date.data_userType[0].id
  },
  // role (state: State) {
  //   if (state.user_date.data_userType[0].id === USER_TYPES.MONITOREO) return 'Empresa de rastreo'
  //   if (state.user_date.data_userType[0].id === USER_TYPES.ADMINISTRADOR) return 'Administrador'
  //   if (state.user_date.data_userType[0].id === USER_TYPES.CLIENTE) return 'Cliente'
  //   if (state.user_date.data_userType[0].id === USER_TYPES.SUPERVISOR) return 'Supervisor'
  //   if (state.user_date.data_userType[0].id === USER_TYPES.USER_MIRROR) return 'Espejo'
  //   return 'Sin datos'
  // },
  // roleId (state: State) {
  //   return state.user_date.data_userType[0].id
  // },
  wtoken (state: State) {
    const wobj = state.plataform_data.find(obj => obj.name === 'WIALON')
    return wobj?.token
  },
  isClient (state: State, getters: { roleId: USER_TYPES }) {
    return getters.roleId === USER_TYPES.CLIENTE
  },
  isEnterprise (state: State, getters: { roleId: USER_TYPES }) {
    return getters.roleId === USER_TYPES.MONITOREO
  },
  isMonitorist (state: State, getters: { roleId: USER_TYPES }) {
    return getters.roleId === USER_TYPES.MONITOREO
  },
  isMirror (state: State, getters: { roleId: USER_TYPES }) {
    return getters.roleId === USER_TYPES.USER_MIRROR
  },
  services (state: State) {
    const services = state.user_date.service.map(service => service.id)
    return services
  },
  hasService (state: State, getters: any) {
    return (service: [SERVICES]) => {
      if (!service) return true
      return getters.services.includes(service)
    }
    // return getters.roleId === USER_TYPES.MONITOREO
  },
  hasMenuAdmin (state: State, getters: any) {
    return (service:any) => {
      if (service.id === MENU_ADMIN.ACCOUNT) {
        if (state.user_date.distribuitor) { return true } else return false
      }
      return true
      // return getters.services.includes(service)
    }
    // return getters.roleId === USER_TYPES.MONITOREO
  },
  isDistribuitor (state: State) {
    return state.user_date.distribuitor
  },
  allowPhotos (state: State) {
    return state.user_date.service.find((service) => service.id === SERVICES.VIAJE)?.settings.allow_photos ?? false
    // return getters.roleId === USER_TYPES.MONITOREO
  },
  maponData (state: State) {
    return state.plataform_data.find(obj => obj.name === 'MAPON')
  },
  webfleetData (state: State) {
    return state.plataform_data.find(obj => obj.name === 'WEB_FLEET')
  },
  getAuthDataToUpdate (state: State) {
    return ({
      id: state.user_date.id,
      username: state.user_date.us_name,
      account_by: state.user_date.account,
      plataform: state.plataform_data.reduce((accum, plataform) => {
        if (plataform.name === 'WIALON') {
          accum.wialonToken = plataform.token
        }
        if (plataform.name === 'MAPON') {
          accum.maponToken = plataform.token
        }
        return accum
      }, { wialonToken: '', maponToken: '' }),
      email: state.user_date.email,
      phone: state.user_date.phone,
      services: state.user_date.service.map((service) => service.id),
      services_settings: state.user_date.service.map((service) => service),
      billing_info: state.user_date.billing_info
    })
  },
  getBasicDataAuth (state: State) {
    return ({
      id: state.user_date.id,
      username: state.user_date.us_name,
      account_by: state.user_date.account,
      email: state.user_date.email,
      phone: state.user_date.phone
    })
  },
  STREAM_RTSP_PARSER () {
    return ''
  },
  hvAccessURL (state: State) {
    // @ts-ignore
    return state.key.HIK_VISION_ACCESS_URL
  },
  hvUsername (state: State) {
    // @ts-ignore
    return state.key.HIK_VISION_USERNAME
  },
  hvPassword (state: State) {
    // @ts-ignore
    return state.key.HIK_VISION_PASSWORD
  },
  getServiceSettings (state: State) {
    return (service_id: SERVICES) => {
      return state.user_date.service.find(service => service.id === service_id)
    }
  },
  userThumbnail (state: State, getters:any, rootState:any) {
    if (!state.user_date?.thumbnail_file) return null
    return `${rootState.app.BASE_URL}${state.user_date.thumbnail_file?.fl_url}`
  },
  accountId (state: State) {
    return state.user_date.account.id
  }
}
