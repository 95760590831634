import { PUBLICATION_TIME_TYPES } from '../catalogs/PUBLICATION_TIME_TYPES'
import { IAccountSocialMedia } from '../interfaces/SocialAccounts.interface'

const state = () => ({
  socialAccounts: [] as IAccountSocialMedia[],
  publishingPreviewData: {
    selectedAccounts: [] as IAccountSocialMedia[],
    form: {
      message: '',
      publication_time_type: PUBLICATION_TIME_TYPES.NOW,
      files: [] as { caption: string, file: File }[]
    }
  }
})

export type State = ReturnType<typeof state>;

export default state
