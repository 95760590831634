// plugins/GlobalFunctions.ts
import { VueConstructor } from 'vue'
import store from '@/store' // Importa tu store Vuex aquí
import { DateTime } from 'luxon'
import { getDateLocalDDMMYYYY, getDateLocalDDMMYYYYhhmmss, getLocalISOTime } from '@/utils/datetimeToDate'

export default {
  install (Vue: VueConstructor) {
    Vue.prototype.$formatNumberWithCommas = (number: number): string => {
      // parseFloat(number.toFixed(2))
      return parseFloat(number.toFixed(2)).toLocaleString()
    }
    Vue.prototype.$getTimezoneOffsetHours = (): number => {
      const now = DateTime.local()
      return now.offset / 60
    }
    Vue.prototype.$getTimezoneOffsetString = (): string => {
      const now = DateTime.local()
      const offset = now.offset / 60
      const sign = offset >= 0 ? '+' : '-'
      const formattedOffset = `${sign}${String(Math.abs(offset)).padStart(2, '0')}:00`
      return formattedOffset
    }
    Vue.prototype.$applyFiltersWithTimezone = function (startDate: string, endDate: string): void {
      const offsetString = this.$getTimezoneOffsetString()
      const startDateTime = `${startDate}T00:00:00.000${offsetString}`
      const endDateTime = `${endDate}T23:59:59.999${offsetString}`
      this.$emit('apply-filters', { startDate: startDateTime, endDate: endDateTime })
    }
    Vue.prototype.$getDateLocalDDMMYYYY = (label:string): string => {
      return getDateLocalDDMMYYYY(label)
    }
    Vue.prototype.$getDateLocalDDMMYYYYhhmmss = (label:string): string => {
      return getDateLocalDDMMYYYYhhmmss(label)
    }
    Vue.prototype.$getLocalISOTime = (): string => {
      return getLocalISOTime()
    }
    Vue.prototype.$toCapitalizeLower = (label:string): string => {
      const label_ = label.toLowerCase()
      return label_.charAt(0).toUpperCase() + label_.slice(1)
    }
    Vue.prototype.$hasPermission = (permissionId: number): boolean => {
      // Obtiene los permisos del usuario del store Vuex
      // @ts-ignore
      const permissions = store.state.auth.user_date.service.flatMap((service: { settings: { permissions: any } }) => service.settings.permissions)
      console.log(permissions)
      // Verifica si el permiso con el ID dado está presente en los permisos del usuario
      return permissions.includes(permissionId)
    }
    Vue.prototype.$canPermission = (permissionPath: string): boolean => {
      // Obtiene los permisos del store
      const permissions = store.state.auth.permissions // Ajusta según tu estructura

      // Divide el path en partes (ejemplo: 'facturacion.cotizacion.view')
      const parts = permissionPath.split('.')
      let current = permissions

      // Navega por el objeto de permisos
      for (const part of parts) {
        if (!current || !current[part]) {
          return false
        }
        current = current[part]
      }

      return true
    }
    Vue.prototype.$copyMessage = async (msPayload: string): Promise<void> => {
      // Obtiene los permisos del usuario del store Vuex
      await navigator.clipboard.writeText(msPayload)
      await store.dispatch('app/notifyToast', { msj: 'Copiado!', type: 'success' })
      // console.log(permissions)
      // Verifica si el permiso con el ID dado está presente en los permisos del usuario
    }
  }
}
