const accountRoutes = {
  meta: {
    title: 'Gestion usuarios'
  },
  path: 'gestion_users',
  component: () => import('@/components/Administration/GestionUser/GestionUser.vue'),
  children: [
    {
      meta: {
        title: 'Gestion usuarios'
      },
      path: '',
      name: 'gestion_users',
      component: () => import('@/components/Administration/GestionUser/GestionUserPanel.vue')
    },
    {
      path: 'grupo_users',
      name: 'grupo_users',
      meta: {
        title: 'Grupo de usuarios'
      },
      component: () => import('@/components/Administration/GestionUser/GroupUser.vue')
    },
    {
      path: 'roles_user',
      name: 'roles_user',
      meta: {
        title: 'Roles de usuario'
      },
      component: () => import('@/components/Administration/Roles/Roles.vue')
    }
  ]
}

export default accountRoutes
