/* eslint-disable no-use-before-define */
/**
 * Separa un string de bounds de Excel (por ejemplo, "A12:K15") en sus componentes de letras y números.
 *
 * @param {string} bounds - El string de bounds a separar.
 * @returns {object} Un objeto con las columnas y filas separadas.
 */
export default function getExcelJSTableBounds (bounds: string): IExcelJSTableBounds {
  // Dividir el string en las dos partes usando el carácter ":"
  const [start, end] = bounds.split(':')

  // Función para separar las letras y los números de una celda
  const separarCelda = (celda: string) => {
    const letterMatch = celda.match(/[A-Z]+/i)
    const letter = letterMatch ? letterMatch[0] : ''
    const digitMatch = celda.match(/\d+/)
    const digit = digitMatch ? parseInt(digitMatch[0], 10) : 0
    return { letter, digit }
  }

  // Separar las celdas de inicio y fin
  const topLeft = separarCelda(start)
  const endRight = separarCelda(end)

  return {
    topLeft,
    endRight
  }
}

export interface IExcelJSTableBounds {
  topLeft: {
    letter: string
    digit: number
  }
  endRight: {
    letter: string
    digit: number
  }
}
