import { IAccountSocialMedia } from '../interfaces/SocialAccounts.interface'
import { State } from './state'

export default {
  SET_SOCIAL_ACCOUNTS (state: State, payload: IAccountSocialMedia[]) {
    state.socialAccounts = payload
  },
  ADD_SOCIAL_ACCOUNTS (state: State, payload: IAccountSocialMedia[]) {
    state.socialAccounts = [...state.socialAccounts, ...payload]
  },
  DELETE_SOCIAL_ACCOUNT (state: State, index: number) {
    state.socialAccounts.splice(index, 1)
  },
  DELETE_SELECTED_SOCIAL_ACCOUNT_BY_INDEX (state: State, index: number) {
    state.publishingPreviewData.selectedAccounts.splice(index, 1)
  },
  ADD_PUBLISH_FORM_FILE (state: State, payload: { caption: string, file: File }) {
    state.publishingPreviewData.form.files.push(payload)
  },
  REMOVE_PUBLISH_BY_INDEX (state: State, index: number) {
    state.publishingPreviewData.form.files.splice(index, 1)
  }
}
