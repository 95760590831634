export enum RESOURCES {
  TRAVELS = 1,
  TRAVELS_INV = 2,
  TRAVELS_FINISHED = 3,
  TRAVELS_NOTIFICATIONS = 4,
  COLORS = 5,
  TRAVEL_TYPES = 6,
  TRAVEL_RESOURCES_IDS = 7,
  TRAVEL_UNITS_IDS = 8,
  TRAVEL_EVENTS = 9,
  ACTUALIZER = 10,
  CLIENTS = 11,
  DASHBOARD = 12,
  TRAVEL_FINISH_COUNT = 13
}

export enum PLATAFORM_RESOURCES {
  UNITS = 1,
  GEOFENCES = 2,
  RESOURCES = 3,
  DRIVERS = 4,
}

export enum PLATAFORM {
  WIALON = 1,
  MAPON = 2,
  HIKVISION = 4,
  STREAMAX = 5,
  WEBFLEET = 6
}

export const PLATAFORMS = [
  { id: 1, name: 'Wialon', icon: '/images/wialon.png' },
  { id: 2, name: 'Mapon', icon: '/images/mapon.png' },
  { id: 6, name: 'WebFleet', icon: '/images/webfleet.png' }
  // { id: 4, name: 'Hikvision', icon: '/images/hikvision_logo.png' },
  // { id: 5, name: 'Streamax', icon: '/images/streamax.png' }
]

export const PLATAFORMS_IMPORTED = [
  { id: 1, name: 'Wialon', icon: '/images/wialon.png' },
  { id: 2, name: 'Mapon', icon: '/images/mapon.png' },
  { id: 5, name: 'Streamax', icon: '/images/streamax.png' },
  { id: 4, name: 'Hikvision', icon: '/images/hikvision_logo.png' },
  { id: 6, name: 'WebFleet', icon: '/images/webfleet.png' }
]
