/**
 * Convierte las letras de una columna de Excel a su número de columna correspondiente.
 * Por ejemplo, "A" => 1, "B" => 2, ..., "AA" => 27, etc.
 *
 * @param {string} letterColumn - La letra(s) de la columna (por ejemplo, "A", "Z", "AA").
 * @returns {number} El número de columna correspondiente.
 */
export default function getColumnPosition (letterColumn: string): number {
  let columna = 0
  const letras = letterColumn.toUpperCase()
  for (let i = 0; i < letras.length; i++) {
    columna *= 26
    columna += letras.charCodeAt(i) - 'A'.charCodeAt(0) + 1
  }
  return columna
}
