import { SOCIAL_MEDIA_TYPE } from '../catalogs/SOCIAL_MEDIA_TYPE'
import { State } from './state'

export default {
  formFilesWithURLImage (state: State) {
    return state.publishingPreviewData.form.files.map(file => {
      return {
        ...file,
        objectURL: URL.createObjectURL(file.file)
      }
    })
  },
  isInstagramValid (state: State) {
    const areInstagramAccount = state.publishingPreviewData.selectedAccounts.some(account => account.id_asm_type === SOCIAL_MEDIA_TYPE.INSTAGRAM)
    if (!areInstagramAccount) return true

    return state.publishingPreviewData.form.files.length
  },
  isValidForm (state: State, getters: any) {
    return state.publishingPreviewData.selectedAccounts.length && getters.isInstagramValid
  }
}
